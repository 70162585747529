<template>
    <div class="framePage-scroll">
              <div class="ovy-a">
                <h3 style="padding-bottom: 20px">基本信息</h3>
                <el-form
                  ref="Form2"
                  :model="Form2"
                  :rules="rules"
                  label-width="130px"
                  class
                >
                  <el-form-item
                    label="班级名称："
                    prop="projectName"
                    class="form-item"
                  >
                    <p>{{Form2.projectName}}</p>
                  </el-form-item>
                  <el-form-item
                    label="行政区划："
                    prop="areaName"
                    class="form-item"
                  >
                      <p>{{Form2.areaName}}</p>
                  </el-form-item>
                  <el-form-item
                    label="有效日期："
                    prop="Effectivedate"
                    class="form-item"
                  >
                    <el-date-picker
                      size="small"
                      v-model="Form2.Effectivedate"
                      type="daterange"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      value-format="timestamp"
                    ></el-date-picker>
                  </el-form-item>
                  <el-form-item
                    label="报名截止时间："
                    prop="signEndDate"
                    class="form-item"
                  >
                    <p>{{Form2.signEndDate}}</p>
                  </el-form-item>
                  <el-form-item
                    label="负责人："
                    prop="projectUser"
                    class="form-item"
                  >
                   <p>{{Form2.projectUser}}</p>
                  </el-form-item>
                  <el-form-item
                    label="负责人电话："
                    prop="projectUserPhone"
                    class="form-item"
                  >
                    <p>{{Form2.projectUserPhone}}</p>
                  </el-form-item>
                  <el-form-item
                    label="培训类型："
                    prop="Trainingtype"
                    class="form-item"
                  >
                    <p>{{Form2.categoryName}}</p>
                  </el-form-item>
                  <el-form-item
                    label="培训人数："
                    prop="projectPeople"
                    class="form-item"
                  >
                    {{Form2.projectPeople}}
                  </el-form-item>
                  <el-form-item
                    label="培训地点："
                    prop="projectAddress"
                    class="form-item"
                  >
                    <p>{{Form2.projectAddress}}</p>
                  </el-form-item>
                  <el-form-item
                    label="期次："
                    prop="projectPc"
                    class="form-item"
                  >
                   <p>{{Form2.projectPc}}</p>
                  </el-form-item>
                  <el-form-item
                    label="考试机构："
                    prop="projectPaperComp"
                    class="form-item"
                  >
                    <p>{{Form2.projectPaperComp}}</p>
                  </el-form-item>
                  <el-form-item
                    label="考试时间："
                    prop="projectPaperDate"
                    class="form-item"
                  >
                   <p>{{Form2.projectPaperDate}}</p>
                  </el-form-item>
                  <el-form-item
                    label="备案课时数："
                    prop="recordClasshours"
                    class="form-item"
                    :rules="[
                    {
                    required: true,
                    message: '请输入备案课时数',
                    trigger: 'blur',
                    },
                    ]"
                  >

                    <el-input-number
                      v-model="Form2.recordClasshours"
                      :min="0"
                      :max="999"
                      style="width: 100%"
                      size="small"
                    ></el-input-number>
                  </el-form-item>
                  <el-form-item
                    label="人群类型："
                    prop="projectCrowd"
                    class="form-item"
                  >
                   <p>{{Form2.projectCrowd == '10'?'重点人群':Form2.projectCrowd == '20'?'企业职工':'其他'}}</p>
                  </el-form-item>
                  <el-form-item
                    label="申请表图片："
                    prop="thumbnail"
                    class="form-item"
                  >
                    <el-upload
                      :on-change="handleAvatarSuccess2"
                      :before-upload="beforeAvatarUpload2"
                      :http-request="$requestMine"
                      :show-file-list="false"
                      class="img-el-upload"
                      action
                      disabled
                      accept="image/png, image/gif, image/jpg, image/jpeg"
                    >
                      <el-image
                        :src="Form2.applicationForm || require('@/assets/develop.png')"
                        fit="contain"
                        class="imgCenter"
                      ></el-image>
                    </el-upload>
                  </el-form-item>
                </el-form>
                <div class="bottom-btn">
                  <el-button @click="cancle" class="bgc-bv">取消</el-button>
                  <el-button @click="seveData('ruleForm')" class="bgc-bv">保存</el-button>
                                    </div>
                                  </div>
                                </div>
                    </template>
                    <script>
                    import UploadPic from "@/mixins/UploadPic";
                    import DatePicker from "@/mixins/DatePicker";
                    import { mapGetters } from "vuex";
                    export default {
                      name: "TestPaper",
                      components: {
                      },
                      mixins: [UploadPic, DatePicker],
                      data() {
                        return {
                          Form2: {
                            Effectivedate: [],
                            recordClasshours: undefined
                          },
                          rules: {
                            Effectivedate: [
                              { required: true, message: "请选择有效时间", trigger: "change" },
                            ],
                          },
                        };
                      },
                      created() {
                        this.projectId = sessionStorage.getItem("projectId");
                        this.projectSource = sessionStorage.getItem("projectSource");
                        this.getDetail2(this.projectId);
                      },
                      computed: {
                        ...mapGetters({
                          userJson: "getUser"
                        })
                      },
                      methods: {
                        seveData(ruleForm) {
                          const el = this.$refs.Form2;
                          el.validate((valid) => {
                            if (valid) {
                              this.doAjax();
                            }
                          });
                        },
                        doAjax() {
                          let parmar = {
                            projectId: this.projectId,
                            startDate: this.Form2.Effectivedate[0],
                            endDate: this.Form2.Effectivedate[1]
                          };
                          if (this.Form2.recordClasshours) {
                            parmar.recordClasshours = this.Form2.recordClasshours;
                          }
                          this.$post("/biz/project/updateProjectInfo", parmar)
                              .then((ret) => {
                                if (ret.status == 0) {
                                  this.$message({
                                    message: ret.message,
                                    type: "success",
                                  });
                                  let timer;
                                  timer = setTimeout(() => {
                                    this.$router.push({
                                      path: "/web/hebei/hebeiProjectList",
                                      query: {
                                        refresh: true,
                                      },
                                    });
                                  },2000)

                                }
                              })
                              .catch((err) => {
                                return;
                              });
                        },
                       getDetail2() {

                         let _this =  this

                         this.$post("/biz/canton/selectCantonProjectDetail", { projectId: this.projectId }).then(
                             (ret) => {
                               let data = ret.data;
                               _this.Form2 = {
                                 ...data
                               };
                               // _this.Form2.Effectivedate =
                               let time = data.startDate&&data.endDate?[new Date(data.startDate).getTime(),new Date(data.endDate).getTime()]:[]
                               this.$set(_this.Form2, 'Effectivedate', time)
                               this.$post("/biz/project/query", { projectId: this.projectId }).then(
                                   (ret2) => {
                                     let data2 = ret2.data;
                                     let res = JSON.stringify(data2);
                                     console.log(data2.recordClasshours)
                                     let number = data2.recordClasshours?''+data2.recordClasshours : undefined
                                     // _this.Form2.recordClasshours =
                                     this.$set(_this.Form2, 'recordClasshours', number)

                                   }
                               );
                             }
                         );
                        },
                        cancle() {
                          this.$router.back();
                        }
                      }
                    };
                    </script>
                    <style lang="less">
                    .el-textarea {
                      .el-textarea__inner {
                        resize: none;
                      }
                    }
                    </style>
                    <style lang="less" scoped>
                    .el-form {
                      display: flex;
                      flex-wrap: wrap;
                      justify-content: space-between;
                      .form-item {
                        width: 45%;
                      }
                    }

                    .bottom-btn {
                      width: 100%;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      margin: 10px 0;
                    }
                    </style>